import { Link, PageProps } from "gatsby"
import React, { useState, useContext } from "react"
import Popover from "react-bootstrap/Popover"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GoogleMap, InfoWindow, LoadScript, Marker } from "@react-google-maps/api"
import mapMarker from "../images/map-marker.png"
import mapMarkerPromo from "../images/map-marker-promo.png"
import { getPhoneNumber } from "../utils/lead-source"
import { formatBedrooms, formatRentRange } from "../utils/format"
import { ModelContext, ModelProvider } from "../hooks/model-context"
import classNames from "classnames"
import tagManager from "../utils/tag-manager"

interface FilterDropdownProps {
  id: string
  state?: string
  setState: Function
  name: string
  items: Array<string>
}

const FilterDropdown: React.FC<FilterDropdownProps> = (props) => {
  return (
    <div className="form-floating">
      <select
        className="form-select"
        id={props.id}
        value={props.state || ""}
        onChange={(event) => {
          props.setState(event.target.value)
        }}
      >
        <option value={""}>Select...</option>

        {props.items.map((item, i) => {
          return (
            <option value={item} key={i}>
              {item}
            </option>
          )
        })}
      </select>
      <label htmlFor={props.id}>{props.name}</label>
    </div>
  )
}

interface FilterMultiSelectDropdownProps {
  id: string
  state?: string[]
  setState: Function
  name: string
  items: string[]
}

const FilterMultiSelectDropdown: React.FC<FilterMultiSelectDropdownProps> = (props) => {
  const popover = (
    <Popover
      id="popover-basic"
      className="shadow p-0 mb-2 bg-body rounded d-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block"
    >
      <Popover.Body>
        <div className="px-4 py-3">
          {props.items.map((item, i) => {
            return (
              <div className="form-check form-switch w-100">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={item}
                  name={item}
                  onChange={(e) => {
                    let newState
                    if (props.state) {
                      if (e.target.checked) {
                        newState = new Set(props.state)
                        newState.add(e.target.name)
                      } else {
                        newState = new Set(props.state)
                        newState.delete(e.target.name)
                      }
                    } else {
                      if (e.target.checked) {
                        newState = [e.target.name]
                      }
                    }
                    newState = Array.from(newState)
                    props.setState(newState)
                  }}
                />
                <label className="form-check-label" htmlFor={item}>
                  {item}
                </label>
              </div>
            )
          })}
        </div>
      </Popover.Body>
    </Popover>
  )

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
      <div
        style={{
          display: "block",
          position: "relative",
          textDecoration: "none",
        }}
      >
        <div
          style={{
            display: "block",
            fontSize: "1rem",
            fontWeight: 400,
            color: "#212529",
            backgroundColor: "#fff",
            border: "1px solid #ced4da",
            borderRadius: "0.25rem",
            paddingTop: "1.625rem",
            paddingBottom: "0.625rem",
            paddingLeft: "0.75rem",
            paddingRight: "2.25rem",
            height: "calc(3.5rem + 2px)",
            lineHeight: "1.25",
            backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' strokeLinejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right 0.75rem center",
            backgroundSize: "16px 12px",
            appearance: "none",
          }}
        >
          Select...
        </div>
        <div
          style={{
            top: 0,
            left: 0,
            height: "100%",
            padding: "1rem 0.75rem",
            opacity: "0.65",
            position: "absolute",
            border: "1px solid transparent",
            display: "inline-block",
            transform: "scale(0.85) translateY(-0.5rem) translateX(0.15rem)",
            transformOrigin: "0 0",
            color: "#212529",
          }}
        >
          Amenities
        </div>
      </div>
    </OverlayTrigger>
  )
}

const FilterBar: React.FC = () => {
  const model = useContext(ModelContext)

  if (model == null) {
    return null
  }

  const {
    priceMin,
    setPriceMin,
    priceMax,
    setPriceMax,
    bedsMin,
    setBedsMin,
    bedsMax,
    setBedsMax,
    baths,
    setBaths,
    amenities,
    setAmenities,
    sort,
    setSort,
  } = model

  return (
    <div className="row mt-2 mb-2 me-2">
      <div className="col">
        <div className="form-floating">
          <input
            className="form-control"
            type="text"
            id="priceMin"
            placeholder="Price Min"
            onChange={(event) => {
              setPriceMin(event.target.value)
            }}
            value={priceMin || ""}
          />
          <label htmlFor="priceMin">Price Min</label>
        </div>
      </div>
      <div className="col">
        <div className="form-floating">
          <input
            className="form-control"
            type="text"
            id="priceMax"
            placeholder="Price Max"
            onChange={(event) => {
              setPriceMax(event.target.value)
            }}
            value={priceMax || ""}
          />
          <label htmlFor="priceMax">Price Max</label>
        </div>
      </div>
      <div className="col">
        <FilterDropdown
          id="bedsMin"
          name="Beds Min"
          state={bedsMin}
          setState={setBedsMin}
          items={["Studio", "1", "2", "3"]}
        />
      </div>
      <div className="col">
        <FilterDropdown
          id="bedsMax"
          name="Beds Max"
          state={bedsMax}
          setState={setBedsMax}
          items={["Studio", "1", "2", "3"]}
        />
      </div>

      <div className="col">
        <FilterDropdown id="baths" name="Baths" state={baths} setState={setBaths} items={["1+", "2+"]} />
      </div>
      <div className="col">
        <FilterMultiSelectDropdown
          id="amenities"
          name="Amenities"
          state={amenities}
          setState={setAmenities}
          items={[
            "Central Heating & Air Conditioning",
            "In Unit Washer & Dryer",
            "Pool",
            "Fitness Center",
            "Dog Friendly",
            "Cat Friendly",
          ]}
        />
      </div>
      <div className="col">
        <FilterDropdown
          id="sort"
          name="Sort"
          state={sort}
          setState={setSort}
          items={["Price (high to low)", "Price (low to high)", "Sq. Ft. (high to low)", "Sq. Ft. (low to high)"]}
        />
      </div>
    </div>
  )
}

const Map = (props: { zoom; center; communities; communityLeadSourceNodes: any }) => {
  const [showMarkers, setShowMarkers] = useState(false)
  const [selectedMarker, setSelectedMarker] = useState<number>()

  return (
    <LoadScript googleMapsApiKey="AIzaSyBpCLUhIOG4a1AUbROQAYB897yv8izdrJA">
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%" }}
        zoom={props.zoom}
        options={googleMapOptions}
        onLoad={(map) => {
          map.panTo(props.center)
          setShowMarkers(true)
        }}
      >
        {showMarkers &&
          props.communities.map((community, index) => {
            const anchorLinkTo = "#" + community.id

            if (community.primaryProperty.latitude == null || community.primaryProperty.longitude == null) {
              console.log(`Skipping ${community.name} without latitude and longitude`)
              return null
            }

            let marker
            let hasPromoMessage = community.floorPlans.some((floorPlan) =>
              floorPlan.promotionalMessage?.includes("Promo")
            )
            if (hasPromoMessage) {
              marker = mapMarkerPromo
            } else {
              marker = mapMarker
            }

            return (
              <Marker
                key={index}
                icon={{
                  url: marker,
                  // @ts-ignore
                  scaledSize: new window.google.maps.Size(50, 50),
                }}
                position={{
                  lat: community.primaryProperty.latitude,
                  lng: community.primaryProperty.longitude,
                }}
                onClick={() => {
                  setSelectedMarker(index)
                  window.location.href = anchorLinkTo
                }}
              >
                {selectedMarker === index && (
                  <InfoWindow
                    onCloseClick={() => {
                      setSelectedMarker(null)
                    }}
                  >
                    <CommunityPopup community={community} communityLeadSourceNodes={props.communityLeadSourceNodes} />
                  </InfoWindow>
                )}
              </Marker>
            )
          })}
      </GoogleMap>
    </LoadScript>
  )
}

const googleMapOptions = {
  disableDefaultUI: true,
  styles: [
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#444444",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          color: "#f2f2f2",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.business",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#b4d4e1",
        },
        {
          visibility: "on",
        },
      ],
    },
  ],
}

const CommunityPopup = (props: { community: any; communityLeadSourceNodes: any }) => {
  const phoneNumber = getPhoneNumber(props.community)

  const address =
    props.community.primaryProperty.addressLine1 +
    ", " +
    props.community.primaryProperty.city +
    ", " +
    props.community.primaryProperty.state +
    " " +
    props.community.primaryProperty.postalCode
  return (
    <div style={{ width: "20em" }}>
      <Link to={props.community.urlPath} className="text-decoration-none text-dark">
        <div style={{ fontSize: "20px", padding: "5px 0px" }}>
          <strong>
            <a href={props.community.urlPath} style={{ textDecoration: "none", color: "#333333" }}>
              {props.community.name}
            </a>
          </strong>
        </div>
        <img
          style={{ height: "100%", width: "100%", padding: "5px 0px" }}
          src={props.community.featuredImage.url}
          alt="Featured Image"
        />
        <div style={{ padding: "5px 0px" }}>
          <a href={props.community.urlPath} style={{ textDecoration: "none", color: "#333333" }}>
            {address}
          </a>
        </div>
      </Link>
      <div className="text-dark text-decoration-none pb-1" style={{ padding: "5px 0px" }}>
        {props.community.rentRanges?.map((rentRange, index) => (
          <div className="text-dark mt-1" key={index}>
            <span>{formatBedrooms(rentRange.bedrooms)} </span>
            <Link to={props.community.urlPath + "/#floor-plans"} className="text-decoration-none pb-1">
              {formatRentRange(rentRange)}
            </Link>
          </div>
        ))}
      </div>
      <div className="row w-100 g-0 pt-3">
        <div className="col me-3">
          <a
            className="btn btn-md btn-light w-100"
            href={`tel:${phoneNumber}`}
            target="_blank"
            style={{
              backgroundColor: "#40abff",
              border: "0px",
              color: "#FFFFFF",
            }}
          >
            {"Call Us"}
          </a>
        </div>

        {props.community.allowOnlineApplications ? (
          <div className="col ms-3 mb-3">
            <a
              className="btn btn-md btn-light w-100"
              href={getApplyLink(props.community?.id)}
              target="_blank"
              style={{
                backgroundColor: "#2a87ff",
                border: "0px",
                color: "#FFFFFF",
              }}
            >
              Apply Now
            </a>
          </div>
        ) : (
          <div className="col ms-3 mb-3">
            <button
              className="btn btn-md btn-light w-100"
              disabled={true}
              style={{
                backgroundColor: "#2a87ff",
                border: "0px",
                color: "#FFFFFF",
              }}
            >
              Unavailable
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

const Image = ({ image, ...props }) => {
  if (image) {
    const gimage = getImage(image.localFile)
    return <GatsbyImage alt={image.caption} image={gimage} {...props} />
  } else {
    return null
  }
}

const CommunityComponent = (props: { id: string; community: any; communityLeadSourceNodes: any }) => {
  const phoneNumber = getPhoneNumber(props.community)

  const fulladdress =
    props.community.primaryProperty.addressLine1 +
    ", " +
    props.community.primaryProperty.city +
    ", " +
    props.community.primaryProperty.state +
    " " +
    props.community.primaryProperty.postalCode

  const address = props.community.primaryProperty.addressLine1

  const city =
    props.community.primaryProperty.city +
    ", " +
    props.community.primaryProperty.state +
    " " +
    props.community.primaryProperty.postalCode

  const mins: number[] = props.community.rentRanges?.map((range) => parseInt(String(range.rentMin)))
  const maxs: number[] = props.community.rentRanges?.map((range) => parseInt(String(range.rentMax)))
  const min = mins?.length > 0 ? Math.min(...mins) : null
  const max = maxs?.length > 0 ? Math.max(...maxs) : null
  const pricerange = formatRentRange({ rentMin: min, rentMax: max })

  return (
    <div
      id={props.id}
      className={classNames("m-3", {
        "opacity-25": pricerange == null,
      })}
    >
      <div>
        <div className="row">
          <div className="col-10 mt-1">
            <h5
              className="mb-0"
              style={{
                fontSize: "22px",
                fontWeight: 500,
              }}
            >
              <a href={props.community.urlPath} style={{ textDecoration: "none", color: "#222222" }}>
                {props.community.name}
              </a>
            </h5>

            <h6 className="mt-1 text-muted mb-0" style={{ opacity: "0.9" }}>
              {address}
            </h6>

            <h6 className="mt-1 text-muted mb-0" style={{ opacity: "0.9" }}>
              {city}
            </h6>

            <h6 className="mt-1 text-muted mb-0" style={{ opacity: "0.7" }}>
              {pricerange != null ? (
                <>
                  <strong>Rent:</strong> {pricerange}
                </>
              ) : (
                "No Units Available"
              )}
            </h6>

            {props.community.websitePromotionalMessage ? (
              <h6 className="mt-1 text-muted mb-0" style={{ opacity: "0.7" }}>
                <span>{props.community.websitePromotionalMessage}</span>
              </h6>
            ) : (
              <h6 className="mt-1 text-muted mb-0" style={{ opacity: "0.7" }}>
                <span></span>
              </h6>
            )}

            {props.community.floorPlans.some((floorPlan) => floorPlan.promotionalMessage?.includes("Promo")) && (
              <h6 className="mt-1 text-muted mb-0 " style={{ opacity: "0.7" }}>
                <span>
                  <strong></strong>
                </span>
              </h6>
            )}

            <h6 className="mt-1 text-muted mb-3"></h6>
          </div>

          {props.community.name == "Encore Apartments" ? (
            <div className="col-2 mt-2">
              <h5
                className="badge"
                style={{
                  float: "right",
                  backgroundColor: "#ff6600",
                }}
              >
                Series 2
              </h5>

              {props.community.floorPlans.some((floorPlan) => floorPlan.promotionalMessage?.includes("Promo")) && (
                <h5
                  className="badge"
                  style={{
                    float: "right",
                    backgroundColor: "#ffc600",
                  }}
                >
                  &nbsp;Promo&nbsp;&nbsp;
                </h5>
              )}
            </div>
          ) : (
            <div className="col-2">
              <h5
                className="badge"
                style={{
                  float: "right",
                  backgroundColor: "#0066cc",
                }}
              >
                Series 1
              </h5>

              {props.community.floorPlans.some((floorPlan) => floorPlan.promotionalMessage?.includes("Get")) && (
                <h5
                  className="badge"
                  style={{
                    float: "right",
                    backgroundColor: "#ffc600",
                  }}
                >
                  &nbsp;Promo&nbsp;&nbsp;
                </h5>
              )}
            </div>
          )}
        </div>
      </div>

      {/*
          FIX CHROME/EDGE SCROLL IMAGE FLICKER:

          Description: Edge issue with Google Chrome and MS Edge Browsers.
          Appeared to be a bug with Gatsby's Image Plugin after doing some
          research and testing. Was able to fix with a CSS class
      */}

      <Link to={props.community.urlPath + "/"}>
        <Image image={props.community.featuredImage} className="listingImage" />
      </Link>

      <div className="row w-100 g-0 my-3">
        <div className="col me-3 d-block d-sm-none">
          <a
            className="btn btn-md btn-light w-100"
            href={`tel:${phoneNumber}`}
            target="_blank"
            style={{
              backgroundColor: "#40abff",
              border: "0px",
              color: "#FFFFFF",
            }}
          >
            Call Us
          </a>
        </div>

        <div className="col me-3 d-none d-sm-block">
          <a
            className="btn btn-md btn-light w-100"
            href={`tel:${phoneNumber}`}
            target="_blank"
            style={{
              backgroundColor: "#40abff",
              border: "0px",
              color: "#FFFFFF",
            }}
          >
            {phoneNumber}
          </a>
        </div>

        <div className="col ms-3 mb-3">
          <a
            className="btn btn-md btn-light w-100"
            href="https://norhart.twa.rentmanager.com/applynow"
            target="_blank"
            style={{
              backgroundColor: "#2a87ff",
              border: "0px",
              color: "#FFFFFF",
            }}
          >
            Apply Now
          </a>
        </div>
      </div>
      <hr style={{ opacity: "0.2" }} />
    </div>
  )
}

const filterCommunities = (communities: Array<any>) => {
  const { priceMin, priceMax, bedsMin, bedsMax, baths, amenities } = useContext(ModelContext)

  let newBedsMin
  let newBedsMax

  if (bedsMin === "Studio") {
    newBedsMin = 0
  } else {
    newBedsMin = parseInt(bedsMin)
  }

  if (bedsMax === "Studio") {
    newBedsMax = 0
  } else {
    newBedsMax = parseInt(bedsMax)
  }

  /**
   * Return Communities that have a maximum bedroom count
   * that is greater than or equal to the minimum bedroom input.
   */
  if (bedsMin) {
    communities = communities.filter((community) => {
      return community.bedroomsMax >= newBedsMin
    })
  }

  /**
   * Return Communities that have a minimum bedroom count
   * that is less than or equal to the maximum bedroom input.
   */
  if (bedsMax) {
    communities = communities.filter((community) => {
      if (bedsMax === "Studio") {
        return community.bedroomsMin === 0
      }
      return community.bedroomsMin <= newBedsMax
    })
  }

  /**
   * Return Communities where bathrooms are greater than or
   * equal to the bath input. Also takes minimum and maximum
   * bedrooms into consideration.
   */
  if (baths) {
    communities = communities.filter((community) => {
      for (let i = 0; i < community.floorPlans.length; i++) {
        if (bedsMax && bedsMin) {
          if (
            (community.floorPlans[i].bedrooms <= newBedsMax || community.floorPlans[i].bedrooms >= newBedsMin) &&
            community.floorPlans[i].bathrooms >= parseInt(baths)
          ) {
            return true
          }
        } else if (bedsMax && !bedsMin) {
          if (community.floorPlans[i].bedrooms <= newBedsMax && community.floorPlans[i].bathrooms >= parseInt(baths)) {
            return true
          }
        } else if (!bedsMax && bedsMin) {
          if (community.floorPlans[i].bedrooms >= newBedsMin && community.floorPlans[i].bathrooms >= parseInt(baths)) {
            return true
          }
        } else if (community.floorPlans[i].bathrooms >= parseInt(baths)) {
          return true
        }
      }
    })
  }

  /**
   * Return Communities where the Maximum rent range is greater
   * than or equal to minimum price input. Also takes minimum and
   * maximum number of bedrooms into consideration. If those inputs
   * are provided, then communities where a floorPlan with a maximum
   * rent that is greater than the minimum price input are returned.
   */
  if (priceMin) {
    communities = communities.filter((community) => {
      for (let i = 0; i < community.rentRanges.length; i++) {
        if (bedsMax && bedsMin) {
          if (
            (community.floorPlans[i].bedrooms <= newBedsMax || community.floorPlans[i].bedrooms >= newBedsMin) &&
            community.floorPlans[i].rentMax >= parseInt(priceMin)
          ) {
            return true
          }
        } else if (bedsMax && !bedsMin) {
          if (community.floorPlans[i].bedrooms <= newBedsMax && community.floorPlans[i].rentMax >= parseInt(priceMin)) {
            return true
          }
        } else if (!bedsMax && bedsMin) {
          if (community.floorPlans[i].bedrooms >= newBedsMin && community.floorPlans[i].rentMax >= parseInt(priceMin)) {
            return true
          }
        } else if (community.rentRanges[i].rentMax >= parseInt(priceMin)) {
          return true
        }
      }
    })
  }

  /**
   * Return Communities where the minimum rent range is less
   * than or equal to maximum price input. Also takes minimum and
   * maximum number of bedrooms into consideration. If those inputs
   * are provided, then communities where a floorPlan with a minimum
   * rent that is less than the maximum price input are returned.
   */
  if (priceMax) {
    communities = communities.filter((community) => {
      for (let i = 0; i < community.rentRanges.length; i++) {
        if (bedsMax && bedsMin) {
          if (
            (community.floorPlans[i].bedrooms <= newBedsMax || community.floorPlans[i].bedrooms >= newBedsMin) &&
            community.floorPlans[i].rentMin <= parseInt(priceMax)
          ) {
            return true
          }
        } else if (bedsMax && !bedsMin) {
          if (community.floorPlans[i].bedrooms <= newBedsMax && community.floorPlans[i].rentMin <= parseInt(priceMax)) {
            return true
          }
        } else if (!bedsMax && bedsMin) {
          if (community.floorPlans[i].bedrooms >= newBedsMin && community.floorPlans[i].rentMin <= parseInt(priceMax)) {
            return true
          }
        } else if (community.rentRanges[i].rentMin <= parseInt(priceMax)) {
          return true
        }
      }
    })
  }

  /**
   * Return communities where the specified amenity is present.
   * Some amenities are unit specific, if that is the case, then
   * communities that have units with that specified amenity are
   * returned.
   */

  if (amenities.length) {
    communities = communities.filter((community) => {
      let allCommunityAmmenities = []

      allCommunityAmmenities.push(...community.amenities.map((item) => item.name))

      allCommunityAmmenities.push(
        ...community.floorPlans.flatMap((floorPlan) => {
          return floorPlan.amenities?.map((item) => item.name)
        })
      )

      loop1: for (let i = 0; i < amenities.length; i++) {
        let amenity = amenities[i]
        if (amenity === "In Unit Washer & Dryer") {
          amenity = "Washer"
        }

        if (amenity === "Dog Friendly") {
          if (community.petPolicies.some((e) => e.petType === "DOG" && e.allowed === true)) {
            continue loop1
          } else {
            return false
          }
        }

        if (amenity === "Cat Friendly") {
          if (community.petPolicies.some((e) => e.petType === "CAT" && e.allowed === true)) {
            continue loop1
          } else {
            return false
          }
        }

        if (allCommunityAmmenities.some((e) => e === amenity)) {
          continue loop1
        } else {
          return false
        }
      }
      return true
    })
  }

  tagManager.push({
    event: "view-community-list",
    units: communities.map((community, index) => {
      return {
        index: index,
        regionId: community.region?.id,
        communityId: community.id,
        communityName: community.name,
      }
    }),
  })

  return communities
}

/**
 * Sorts communities based on user input. Also takes maximum
 * number of bedrooms into consideration. If a maximum number
 * of bedrooms is input as a filter, then the sort will be based
 * off the floorPlan price and floorPlan square feet of a
 * floorPlan with the specified number of bedrooms as opposed to
 * taking the highest/lowest price/square foootage.
 *
 * @param communities
 */
const sortCommunities = (communities: Array<any>) => {
  const { sort, bedsMax } = useContext(ModelContext)

  if (sort === "Price (high to low)") {
    communities = communities.sort((a, b) => {
      let newA = a.rentMax
      let newB = b.rentMax

      if (bedsMax) {
        for (let i = 0; i < a.rentRanges.length; i++) {
          if (a.rentRanges[i].bedrooms === parseInt(bedsMax)) {
            newA = a.rentRanges[i].rentMax
          }
        }
        for (let j = 0; j < b.rentRanges.length; j++) {
          if (b.rentRanges[j].bedrooms === parseInt(bedsMax)) {
            newB = b.rentRanges[j].rentMax
          }
        }
      }

      if (newA < newB) {
        return 1
      } else {
        return -1
      }
    })
  } else if (sort === "Price (low to high)") {
    communities = communities.sort((a, b) => {
      let newA = a.rentMin
      let newB = b.rentMin

      if (bedsMax) {
        for (let i = 0; i < a.rentRanges.length; i++) {
          if (a.rentRanges[i].bedrooms === parseInt(bedsMax)) {
            newA = a.rentRanges[i].rentMin
          }
        }
        for (let j = 0; j < b.rentRanges.length; j++) {
          if (b.rentRanges[j].bedrooms === parseInt(bedsMax)) {
            newB = b.rentRanges[j].rentMin
          }
        }
      }

      if (newA > newB) {
        return 1
      } else {
        return -1
      }
    })
  } else if (sort === "Sq. Ft. (high to low)") {
    communities = communities.sort((a, b) => {
      let fpMaxA = 0
      let fpMaxB = 0
      for (let i = 0; i < a.floorPlans.length; i++) {
        if (a.floorPlans[i].squareFeetMax > fpMaxA) {
          if (bedsMax) {
            if (a.floorPlans[i]?.bedrooms === parseInt(bedsMax)) {
              fpMaxA = a.floorPlans[i].squareFeetMax
            }
          } else {
            fpMaxA = a.floorPlans[i].squareFeetMax
          }
        }
      }
      for (let j = 0; j < b.floorPlans.length; j++) {
        if (b.floorPlans[j].squareFeetMax > fpMaxB) {
          if (bedsMax) {
            if (a.floorPlans[j]?.bedrooms === parseInt(bedsMax)) {
              fpMaxB = b.floorPlans[j].squareFeetMax
            }
          } else {
            fpMaxB = b.floorPlans[j].squareFeetMax
          }
        }
      }
      if (fpMaxA < fpMaxB) {
        return 1
      } else {
        return -1
      }
    })
  } else if (sort === "Sq. Ft. (low to high)") {
    communities = communities.sort((a, b) => {
      let fpMinA = 0
      let fpMinB = 0
      for (let i = 0; i < a.floorPlans.length; i++) {
        if (a.floorPlans[i].squareFeetMin < fpMinA) {
          if (bedsMax) {
            if (a.floorPlans[i]?.bedrooms === parseInt(bedsMax)) {
              fpMinA = a.floorPlans[i].squareFeetMin
            }
          } else {
            fpMinA = a.floorPlans[i].squareFeetMin
          }
        }
      }
      for (let j = 0; j < b.floorPlans.length; j++) {
        if (b.floorPlans[j].squareFeetMin < fpMinB) {
          if (bedsMax) {
            if (a.floorPlans[j]?.bedrooms === parseInt(bedsMax)) {
              fpMinB = b.floorPlans[j].squareFeetMin
            }
          } else {
            fpMinB = b.floorPlans[j].squareFeetMin
          }
        }
      }
      if (fpMinA > fpMinB) {
        return 1
      } else {
        return -1
      }
    })
  } else {
    communities = communities.sort((a, b) => {
      if (a.primaryProperty.dateBuilt < b.primaryProperty.dateBuilt) {
        return 1
      } else if (a.primaryProperty.dateBuilt > b.primaryProperty.dateBuilt) {
        return -1
      }
      return 0
    })
  }

  return communities
}

const getApplyLink = (communityId?: string, floorPlanId?: string): string => {
  if (floorPlanId) {
    return "/application/?floor-plan=" + floorPlanId
  }

  if (communityId) {
    return "/application/?community=" + communityId
  }
  return "/application/"
}

type CommunityLeadSourceProps = PageProps<{ allCommunityLeadSource }>
interface ApartmentsProps {
  communities: Array<any>
  communityLeadSource: CommunityLeadSourceProps
  selectedTab: string
}

const ApartmentsTemplate: React.FC<ApartmentsProps> = (props) => {
  let communities = props.communities
  communities = communities.filter((community) => !community.name.includes("House"))
  communities = communities.filter((community) => !community.name.includes("Duplex"))

  communities.forEach((community) => {
    community.phoneNumber = getPhoneNumber(community)
  })

  communities = filterCommunities(communities)
  communities = sortCommunities(communities)

  return (
    <section id="apartments" className="bg-white d-flex flex-column">
      <div id="apartmentsMobile">
        <ApartmentsMobile
          communities={communities}
          communityLeadSource={props.communityLeadSource}
          selectedTab={props.selectedTab}
        />
      </div>
      <div id="apartmentsDesktop">
        <ApartmentsDesktop communities={communities} communityLeadSource={props.communityLeadSource} />
      </div>
    </section>
  )
}

interface ApartmentsMobileProps {
  communities: Array<any>
  communityLeadSource: CommunityLeadSourceProps
  selectedTab: string
}

const ApartmentsMobile: React.FC<ApartmentsMobileProps> = ({ communities, communityLeadSource, selectedTab }) => {
  if (selectedTab === "promo") {
    communities = communities.filter((community) =>
      community.floorPlans.some((floorPlan) => floorPlan.promotionalMessage?.includes("Promo"))
    )
  }

  return (
    <>
      <div
        className={classNames("mapArea", {
          hidden: selectedTab !== "map",
        })}
      >
        <Map
          zoom={10}
          center={{ lat: 45.13, lng: -93.1 }}
          communities={communities}
          communityLeadSourceNodes={communityLeadSource}
        />
      </div>

      <div
        className={classNames("communitiesArea", {
          hidden: selectedTab !== "communities" && selectedTab !== "promo",
        })}
      >
        {communities.length > 0 ? (
          communities.map((community, index) => {
            return (
              <CommunityComponent
                id={community.id}
                community={community}
                communityLeadSourceNodes={communityLeadSource}
                key={index}
              />
            )
          })
        ) : (
          <div style={{ textAlign: "center", height: "100%" }}>
            <h5>Sorry, there are no properties that matched your search.</h5>
          </div>
        )}
      </div>
    </>
  )
}

interface ApartmentsDesktopProps {
  communities: Array<any>
  communityLeadSource: CommunityLeadSourceProps
}

const ApartmentsDesktop: React.FC<ApartmentsDesktopProps> = ({ communities, communityLeadSource }) => {
  return (
    <>
      <div className="filterArea">
        <FilterBar />
      </div>

      <div className="mapArea">
        <Map
          zoom={10}
          center={{ lat: 45.13, lng: -93.1 }}
          communities={communities}
          communityLeadSourceNodes={communityLeadSource}
        />
      </div>

      <div className="communitiesArea">
        {communities.length > 0 ? (
          communities.map((community, index) => {
            return (
              <CommunityComponent
                id={community.id}
                community={community}
                communityLeadSourceNodes={communityLeadSource}
                key={index}
              />
            )
          })
        ) : (
          <div style={{ textAlign: "center", height: "100%" }}>
            <h5>Sorry, there are no properties that matched your search.</h5>
          </div>
        )}
      </div>
    </>
  )
}

const Apartments: React.FC<ApartmentsProps> = (props) => {
  return (
    <ModelProvider>
      <ApartmentsTemplate {...props} />
    </ModelProvider>
  )
}

/** export */
export default Apartments
